import React from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout/layout';
import Seo from '../components/seo';
import ogImage from '../images/seo/SEO_MBIP.jpg';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { graphql } from 'gatsby';

const RegulationsPage = ({ pageContext }) => {
  const { language } = useI18next();
  const { t } = useTranslation();

  const node_language = (language === "pl") ? "pl-PL" : "en"

  const [content, setContent] = React.useState({ raw: "" })

  React.useEffect(() => {
    pageContext.edges.forEach(({ node }) => {
      if (node.slug === pageContext.mainSlug && node_language === node.node_locale) {
        setContent(node.content)
      }
    })
  }, [])

  return (
    <Layout>
      <Seo
        title={t('regulations.page-title')}
        description={t('regulations.page-description')}
        ogImage={ogImage}
      />
      <section className="page-section container legal" id="regulations">
        {
          (content.raw === "") ?
            <p>Loading...</p>
            :
            renderRichText(content)
        }
      </section>
    </Layout>
  );
};

export default RegulationsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
